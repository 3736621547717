<template>
  <div class="media-manager">
    <modal
      :active="createNewMediaActive"
      @dismiss="createNewMediaActive = false"
      class="create-media-modal form-control"
    >
      <template v-if="newMedia">
        <h2>Upload New Media</h2>

        <div class="form-row">
          <label>Image</label>
          <input
            type="file"
            @change="onFileChanged"
            accept="image/png, image/jpeg, image/svg+xml"
          />
        </div>

        <div class="form-row">
          <label>Name</label>
          <input v-model="newMedia.name" type="text" />
        </div>

        <div class="form-row">
          <label>Description</label>
          <textarea v-model="newMedia.description"></textarea>
        </div>

        <button class="button" @click="onCreate">Upload</button>
      </template>
    </modal>

    <modal
      :active="editMediaActive"
      @dismiss="editMediaActive = false"
      class="create-media-modal form-control"
    >
      <template v-if="activeMedia">
        <h2>Edit Media</h2>

        <div v-if="activeMedia.url" class="create-media-modal__image">
          <BaseImage
            v-if="activeMedia.type === 'image'"
            :src="activeMedia.url"
          />
          <BaseSVG v-else :src="activeMedia.url" />
        </div>

        <div class="form-row">
          <label>Replace Image</label>
          <input
            type="file"
            @change="onEditFileChanged"
            accept="image/png, image/jpeg, image/svg+xml"
          />
        </div>

        <div class="form-row">
          <label>Name</label>
          <input v-model="activeMedia.name" type="text" />
        </div>

        <div class="form-row">
          <label>Description</label>
          <textarea v-model="activeMedia.description"></textarea>
        </div>

        <button class="button" @click="onUpdate">Update</button>
      </template>
    </modal>

    <modal
      :active="viewMediaActive"
      @dismiss="viewMediaActive = false"
      class="view-media-modal form-control"
    >
      <template v-if="activeMedia">
        <figure class="view-media-modal__image">
          <BaseImage
            v-if="activeMedia.type === 'image'"
            :src="activeMedia.url"
          />
          <BaseSVG v-else :src="activeMedia.url" />
        </figure>
        <div class="view-media-modal__settings">
          <div class="view-media-modal__meta">
            <p class="view-media-modal__title">{{ activeMediaName }}</p>
            <p class="view-media-modal__description">
              {{ activeMedia.description || "" }}
            </p>
            <p class="view-media-modal__url">
              {{ activeMedia.url || "" }}
            </p>
          </div>
          <button class="button" @click="onActivateEdit">Edit</button>
        </div>
      </template>
    </modal>

    <loading :active="loading" />

    <div class="hero hero--engage hero--media">
      <div class="container">
        <h2>Media</h2>

        <div class="filterbar">
          <div class="filterbar__input">
            <input
              type="text"
              v-model="filter"
              v-on:keyup.enter="onFilterMedia"
            />
          </div>
          <div class="filterbar__submit">
            <button @click="onFilterMedia">Filter</button>
          </div>
        </div>
        <button class="button" @click="onNewMedia">Upload New Media</button>
      </div>
    </div>

    <div class="container">
      <div class="media-wrapper">
        <div
          class="media-item"
          v-for="(item, index) in filteredMedia"
          :key="`media-item-${index}`"
          @click="onViewMedia(item)"
        >
          <BaseImage v-if="item.type === 'image'" :src="item.url" />
          <BaseSVG v-else :src="item.url" />
        </div>
      </div>

      <Pagination
        :items="media"
        :pageLength="pageLength"
        @paginate="onPaginate"
        v-show="filteredMedia.length"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import BaseImage from "@/components/base/BaseImage";
import BaseSVG from "@/components/base/BaseSVG";
import Modal from "@/components/Modal";
import Loading from "@/components/Loading";
import Media from "@/models/Media";
import Pagination from "@/components/Pagination";

export default {
  name: "Media",
  components: {
    Modal,
    Loading,
    BaseImage,
    BaseSVG,
    Pagination,
  },
  data() {
    return {
      createNewMediaActive: false,
      editMediaActive: false,
      pageLength: 24,
      viewMediaActive: false,
      activeMedia: false,
      newMedia: null,
      filter: null,
      type: "",
      filteredMedia: [],
    };
  },
  computed: {
    loading() {
      return this.$store.getters["media/loading"];
    },
    media() {
      return this.$store.getters["media/media"];
    },
    activeMediaName() {
      if (this.activeMedia) {
        if (this.activeMedia.name) {
          return this.activeMedia.name;
        }

        const parts = this.activeMedia.url.split("/");
        return parts.pop();
      }

      return "";
    },
  },
  methods: {
    onNewMedia() {
      this.newMedia = new Media();
      this.createNewMediaActive = true;
    },
    onViewMedia(item) {
      this.activeMedia = item;
      this.viewMediaActive = true;
      if (this.activeMedia.name === null) {
        this.activeMedia.name = this.activeMediaName;
      }
    },
    onFileChanged(event) {
      this.newMedia.image = event.target.files[0];
      this.newMedia.name = this.newMedia.image.name;
    },
    onEditFileChanged(event) {
      this.activeMedia.image = event.target.files[0];
    },
    onCreate() {
      const formData = new FormData();
      formData.append("image", this.newMedia.image);
      formData.append("name", this.newMedia.name);
      formData.append("description", this.newMedia.description);

      this.$store.dispatch("media/addMedia", formData).then(() => {
        this.newMedia = null;
        this.createNewMediaActive = false;

        this.$store.dispatch("media/getMedia").then(() => {
          this.onLastPage();
        });
      });
    },
    onActivateEdit() {
      this.activeMedia.image = null;
      this.viewMediaActive = false;
      this.editMediaActive = true;
    },
    onUpdate() {
      const page = this.page;
      const formData = new FormData();
      formData.append("id", this.activeMedia.id);
      formData.append("image", this.activeMedia.image);
      formData.append("name", this.activeMedia.name);
      formData.append("description", this.activeMedia.description);

      this.$store.dispatch("media/updateMedia", formData).then(() => {
        this.activeMedia = null;
        this.editMediaActive = false;

        this.$store.dispatch("media/getMedia").then(() => {
          this.page = page;
        });
      });
    },
    onFilterMedia() {
      this.$store.dispatch("media/getMedia", { filter: this.filter });
    },
    onPaginate(items) {
      this.filteredMedia = items;
    },
  },
  mounted() {
    if (!this.media.length || !this.filteredMedia.length) {
      this.$store.dispatch("media/getMedia");
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/colors";
@import "@/scss/button";
@import "@/scss/forms";
@import "@/scss/table";
@import "@/scss/hero";
@import "@/scss/media";
@import "@/scss/pagination";
@import "@/scss/filter";

.hero--media {
  .container {
    justify-content: space-between;

    .button {
      margin-left: 0 !important;
    }
  }
}

.media-manager {
  .form-row {
    flex-grow: 1;
    margin-bottom: 1em;

    input[type="file"] {
      margin-bottom: 1.25rem;
    }

    label {
      display: block;
      margin-bottom: 6px;
    }

    textarea {
      height: 75px;
    }
  }

  .media-wrapper {
    .media-item {
      padding-bottom: calc(16.666% * 0.75);
      width: 16.666%;
    }
  }
}

.view-media-modal,
.create-media-modal {
  &__image {
    align-items: center;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }

  .image-wrapper__item,
  svg {
    max-height: 500px;
    max-width: 100%;
    width: auto;
  }
}

.view-media-modal {
  .modal-content {
    width: 960px;
  }

  &__title {
    font-weight: bold;
    margin-top: 0.5em;
  }

  &__settings {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
  }
}

.create-media-modal {
  &__image {
    margin-bottom: 1rem;
  }
}
</style>