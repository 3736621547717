<template>
  <div class="pagination">
    <div class="pagination__prev">
      <BaseSVG
        class="pagination__first"
        :class="{ disabled: previousPageActive === false }"
        :src="require('@/assets/double-arrow-icon.svg')"
        @click="onFirstPage"
      />
      <button
        class="button button--prev button--reset"
        :class="{ 'button--disabled': previousPageActive === false }"
        @click="onPreviousPage"
      >
        Previous
      </button>
    </div>
    <div class="pagination__count">
      Showing <strong>{{ firstResult }}</strong> to
      <strong>{{ lastResult }}</strong> of
      <strong>{{ items.length }}</strong> results
    </div>
    <div class="pagination__next">
      <button
        class="button button--next button--reset"
        :class="{ 'button--disabled': nextPageActive === false }"
        @click="onNextPage"
      >
        Next
      </button>
      <BaseSVG
        class="pagination__last"
        :class="{ disabled: nextPageActive === false }"
        :src="require('@/assets/double-arrow-icon.svg')"
        @click="onLastPage"
      />
    </div>
  </div>
</template>

<script>
import BaseSVG from "@/components/base/BaseSVG";

export default {
  name: "Pagination",
  props: {
    items: {
      required: true,
      type: Array,
    },
    pageLength: {
      required: false,
      type: Number,
      default: 12,
    },
  },
  components: {
    BaseSVG,
  },
  data() {
    return {
      page: 1,
    };
  },
  watch: {
    filteredItems: function () {
      this.$emit("paginate", this.filteredItems);
    },
    items: function () {
      this.page = 1;
    },
  },
  computed: {
    nextPageActive() {
      return (
        this.items.slice(
          this.page * this.pageLength,
          (this.page + 1) * this.pageLength
        ).length > 0
      );
    },
    previousPageActive() {
      return this.page > 1;
    },
    filteredItems() {
      let page = this.page - 1;
      if (page < 0) {
        page = 0;
      }

      return this.items.slice(
        page * this.pageLength,
        this.page * this.pageLength
      );
    },
    numberOfPages() {
      return Math.ceil(this.items.length / this.pageLength);
    },
    firstResult() {
      return (this.page - 1) * this.pageLength + 1;
    },
    lastResult() {
      return this.firstResult - 1 + this.filteredItems.length;
    },
  },
  methods: {
    onFirstPage() {
      this.page = 1;
    },
    onLastPage() {
      this.page = Math.ceil(this.items.length / this.pageLength);
    },
    onPreviousPage() {
      this.page--;
    },
    onNextPage() {
      this.page++;
    },
  },
  mounted() {
    this.$emit("paginate", this.filteredItems);
  },
};
</script>

<style lang="scss">
@import "@/scss/pagination";
</style>

